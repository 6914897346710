html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.6;
  color: #333;
  background-color: white;
  margin: 0;
  padding: 0;
}

.nav-visible {
  opacity: 1;
  transition: opacity 0.15s;
  background-color: transparent;
}

.nav-hidden {
  opacity: 0.4;
  background-color: transparent;
  transition: opacity 0.3s;
}

.about {
  padding-bottom: 0;
}

.profile-image {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 40px;
  margin-bottom: 20px;
  justify-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 15vh;
}

/* Navigation */
header {
  background-color: #fff;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}

nav a {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

nav a:hover {
  color: #666;
}

.logo {
  width: 50px;
}

.about-container {
  /* background-color: #000000; 
  color: #ffffff;  */
  padding: 20px; /* Add some padding for the content */
  width: 40vw;
  margin: 0 auto; /* Center the container horizontally */
  left: 0;
  text-align: left;
}

.portfolio-container {
  /* background-color: #000000; 
  color: #ffffff;  */
  padding: 20px; /* Add some padding for the content */
  width: 40vw; /* Set the width to 50% */
  margin: 0 auto; /* Center the container horizontally */

  text-align: left;
}

.contact-container {
  padding: 20px; /* Add some padding for the content */
  width: 40vw; /* Set the width to 50% */
  margin: 0 auto; /* Center the container horizontally */

  text-align: left;
}

.links-container ul a,
.links-container ul a:hover,
.links-container ul a:active,
.links-container ul a:visited {
  color: white;
}

.portfolio-container ul a,
.portfolio-container ul a:hover,
.portfolio-container ul a:active,
.portfolio-container ul a:visited {
  color: black;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.italics {
  font-style: italic;
}

.logo:hover {
  opacity: 60%;
}

/* Main Content */
main {
  padding-top: 4rem;
}

/* Section Styles */
section {
  padding: 4rem 0rem;
  text-align: center;
}

.portfolio {
  padding: 0rem;
}

.interests {
  padding: 0rem;
}

.contact {
  padding-top: 0rem;
  padding-bottom: 8rem;
}

.projects {
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  border: 5px solid black;
  padding: 20px;
}

.project-container {
  display: flex;
  justify-content: space-between;
}

.screenshot-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.screenshot {
  width: auto;
  height: 70%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-bottom: 20px;
}

/* Footer */
footer {
  background-color: #333;
  color: #ffffff;
  text-align: center;
  padding: 1.5rem 0;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  padding: 0.25rem;
}

footer a:hover {
  color: #ccc;
}

/* Add the following media query at the end of your CSS file */
@media (max-width: 768px) {
  .about-container,
  .portfolio-container,
  .contact-container {
    width: 80%; /* You can adjust this value to 90% or any other percentage based on your preference */
  }

  .nav-hidden {
    opacity: 0;
  }
}

.menu {
  display: flex;
}

/* ... (previous CSS code) ... */

.menu {
  display: flex;
}

@media (max-width: 768px) {
  nav a {
    font-size: 0.9rem; /* Reduce the font size on smaller screens */
    padding: 0.25rem 0.5rem; /* Adjust the padding */
  }
}

@media (max-width: 480px) {
  nav a {
    font-size: 0.8rem; /* Further reduce the font size on even smaller screens */
    padding: 0.25rem 0.3rem; /* Adjust the padding */
  }
}

/* ... (rest of the CSS code) ... */
